<template>
  <div class="videoCallOuter" id="screen_capture">
    <div class="loaderOuter" v-if="isLoading==true" style="z-index:1;">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div
      class="alert alert-success"
      :class="{ 'alert-active': successMessage != null }"
    >
      <strong>Success!</strong> {{ successMessage }}
    </div>
    <!-- <div class="videoCanvas"> -->
    <div id="videos-container" class="videoCanvas">
      <!-- Join Call Option -->
      <div class="joinVideoOption">
        <span
          id="join-room"
          @click="joinVideoCall()"
          class="joinVideoButton"
          title="Join Video Call"
        >
          <i class="bi bi-camera-video-fill"></i> Join Now
        </span>
      </div>
      <!-- Start Call Option -->
      <div class="startVideoCallOption">
        <span id="open-room" @click="startVideoCall()" title="Start Video Call">
          <i class="bi bi-camera-video-fill"></i>Start</span
        >
      </div>
      <!-- Video Call Button Action -->
      <div class="callButtonGroup">
        <input
          type="hidden"
          id="room-id"
          v-model="roomid"
          autocorrect="off"
          autocapitalize="off"          
        />
        <input
          type="hidden"
          id="patient-id"
          v-model="patient_uid"
          autocorrect="off"
          autocapitalize="off"          
        />

        <button id="btnMute" title="Turn Off Mic ">
          <span class="bi-mic-fill"></span>
        </button>
        <button id="btnUnMute" title="Turn On Mic ">
          <span class="bi-mic-mute-fill"></span>
        </button>
        <button class="button" id="off-cam" title="Turn Off Video ">
          <span class="bi-camera-video-fill"></span>
        </button>
        <button class="button" id="start-cam" title="Turn On Video">
          <span class="bi-camera-video-off-fill"></span>
        </button>
        <button
          id="btnLeave"
          @click="endVideoCall()"
          class="endVideoCall"
          title="End Call"
        >
          <span class="bi bi-telephone-fill"></span>
        </button>
        <button
          v-if="this.patient_uid!=null"
          @click="captureScreenshot"
          data-bs-toggle="modal"
          data-bs-target="#modal_caputre"
          id="screencapture"
          title="Screen Capture"
        >
          <span class="bi bi-camera"></span>
        </button>
        <button id="open-or-join-room">Auto Open Or Join Room</button>
      </div>
      <!-- <video autoplay="" playsinline=""></video> -->
    </div>

    <table id="deviceError">
      <tr>
        <td class="text-danger error-text">Please Check Your Devices And Permission</td>
      </tr>
      <tr >
        <td id="camError"><h1 class="bi bi-webcam"></h1> <span class="text-danger error-text">Not Connected</span> </td>
        <td id="microphoneError"><h1 class="bi bi-headset"></h1> <span class="text-danger error-text">Not Connected</span></td>
        <!-- <td id="speakerError"><h1 class="bi bi-speaker"></h1> <span class="text-danger error-text">Not Connected</span></td>         -->
      </tr>
      <tr>
        <td>
          <button class="custom-button tableButton" @click="checkDevices()">Check Device </button>
        </td>
      </tr>
    </table>

    <!-- <div>
      <span  class="text-danger error-text">Please attach your camera and check permission to your browser.</span>
    </div>
    <div>  
      <span id="microphoneError" class="text-danger error-text">Please attach your Microphone and check permission to your browser.</span>
    </div>
    <div>
      <span id="speakerError" class="text-danger error-text">Please attach a speaker device. You will unable to hear the incoming audios.</span>
    </div> -->

    <div id="room-urls"></div>
    <!-- </div> -->
    <canvas
      id="canvas"
      ref="myCanvas"
      style="display: none"
      width="300"
      height="200"
    ></canvas>
    <img
      style="display: none"
      id="photo"
      alt="The screen capture will appear in this box."
    />
    <!-- <div
      id="modal-caputre"
      title="Capture Screenshot"
      ok-title="Save"
      @ok="saveBase64"
    >
      <img
        style="display: block; margin: 0 auto; z-index: 999 !important"
        :src="base64"
        id="photo1"
        alt="The screen capture will appear in this box."
      />
    </div> -->
    <!-- Video Capture Modal -->
    <div class="modal fade" id="modal_caputre">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content custom-modal-content">
          <div class="close-modal">
            <span class="bi bi-x" data-bs-dismiss="modal"></span>
          </div>
          <h4>Consultation Screen</h4>
          <img
            :src="base64"
            id="photo1"
            alt="The screen capture will appear in this box."
          />
          <div class="custom-btn-group">
            <button
              type="button"
              class="custom-button"
              title="Save Screen Shot"
              data-bs-dismiss="modal"
              @click="saveBase64()"
            >
              Save
            </button>
            <button
              type="button"
              class="custom-button custom-delete-button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import html2canvas from "html2canvas";
import useService from "../services/Service.js";
import moment from "moment";
const { getData, getByID, postData } = useService();
export default {
  data() {
    return {
      base64: "",
      user_ip: "",
      roomid: "",
      patient_ip: "",
      physicianName: "",
      physicianIP: "",
      patientName: "",
      patientIP: "",
      successMessage: null,
      patient_uid:null,
      isLoading: true,
    };
  },
  methods: {
    async captureScreenshot() {
      var width = 550; // We will scale the photo width to this
      //var height = 309;     // This will be computed based on the input stream
      var height = 450; // This will be computed based on the input stream
      var streaming = false;
      var video = null;
      var canvas = null;
      var photo = null;

      try {
        const parametter = this.roomid;
        const resData = await getByID(
          "physicians/np/getVideoConferenceDataByRoomId/",
          parametter
        );
        if (resData.status === 200 && resData.data.statuscode === 1) {
          this.patientName = resData.data.result.patient_data.name;
          this.patientIP = resData.data.result.patient_data.ipadress;

          this.physicianName = resData.data.result.user_data.name;
          this.physicianIP = resData.data.result.user_data.ipadress;
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }

      var physicianName = this.physicianName;
      var physicianIP = this.physicianIP;
      var patientName = this.patientName;
      var patientIP = this.patientIP;

      var video = document.getElementById("remoteVideo");
      var canvas = document.getElementById("canvas");
      var photo = document.getElementById("photo");

      var context = canvas.getContext("2d");
      if (width && height) {
        canvas.width = width;
        canvas.height = height;
        context.drawImage(video, 0, 0, width, height);
        var data = canvas.toDataURL("image/png");
        photo.setAttribute("src", data);
        // Save Image
        var hdimg = canvas.toDataURL("image/jpg");
        //return hdimg;
      }
      var localVideo = document.getElementById("localVideo");
      context.drawImage(localVideo, 450, 10, 100, 150);
      context.font = "20px Georgia";
      context.fillStyle = "#FFFFFF";
      context.fillText("Physician- " + physicianName, 10, 50);
      context.fillText("Ip Address- " + physicianIP, 10, 70);
      context.fillText("Patient- " + patientName, 10, 90);
      context.fillText("Ip Address- " + patientIP, 10, 110);
      var data1 = canvas.toDataURL("image/png");
      photo.setAttribute("src", data1);
      this.base64 = data1;
      //$("#photo").show();
      //this.saveBase64();
    },

    async saveBase64() {
      const imageData = {
        conference_room_id: this.roomid,
        capture_image: this.base64,
        video_conference: 3,
      };
      try {
        const resData = await postData(
          "physicians/np/createVideoConferenceData",
          imageData
        );
        if (
          resData.data.message == "success" &&
          resData.data.statuscode === 1
        ) {
          console.log("Success");
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
      this.successMessage = "Consulation screen is captured successfully.";
      this. hideMsg();      
    },
    hideMsg(){
      setTimeout(() =>       
       this.successMessage = null 
      , 3000);
    },
    draw() {
      let canvas = document.getElementById("canvas");
      let c = canvas.getContext("2d");
      c.fillStyle = "rgba(255, 0, 0, 0.5)";
      c.fillRect(100, 50, 30, 30);
    },
    callToWebrtc() {
      //webrtc();
      alert("Webrtc");
    },
    startVideoCall() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      this.patient_uid = this.$route.params.patient_uid;
      const call_status = 3;      
      this.updateCallStatus(user_uid, call_status);
      this.createConference(user_uid, this.patient_uid);
    },
    async joinVideoCall() {
      this.isLoading= false;
      const resData = await getData("administrator/common/getUserIpAddress");
      if (resData.data.statuscode === 1) {
        this.patient_ip = resData.data.result;
      }
      const Data = {
        conference_room_id: this.roomid,
        patient_ip_address: this.patient_ip,
        video_conference: 2,
      };
      try {
        const resData = await postData(
          "physicians/np/createVideoConferenceData",
          Data
        );
        if (
          resData.data.message == "success" &&
          resData.data.statuscode === 1
        ) {
          console.log("Success");
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    endVideoCall() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      const call_status = 1;
      this.updateCallStatus(user_uid, call_status);
      this.setRoomid();
    },

    async updateCallStatus(uid, call_status) {
      const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const data = {
        user_uid: uid,
        call_status: call_status,
        available_time: currentDate,
      };

      try {
        const resData = await postData(
          "physicians/admin/updateUserExtensionStatus",
          data
        );
        if (
          resData.data.message == "success" &&
          resData.data.statuscode === 1
        ) {
          console.log("Success");
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    async createConference(user_uid, patient_uid) {
      const resData = await getData("administrator/common/getUserIpAddress");
      if (resData.data.statuscode === 1) {
        this.user_ip = resData.data.result;
      }

      const data = {
        user_uid: user_uid,
        user_ip_address: this.user_ip,
        patient_uid: patient_uid,
        conference_room_id: this.roomid,
        video_conference: 1,
      };
      try {
        const resData = await postData(
          "physicians/np/createVideoConferenceData",
          data
        );
        if (
          resData.data.message == "success" &&
          resData.data.statuscode === 1
        ) {
          console.log("Success");
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },

    setRoomid() {
      var id = Math.random().toString(16).slice(2);
      setTimeout(() => webrtc(), 3000);
      var url = window.location.search;
      url = url.replace("?roomid=", ""); // remove the ?
      $("#btnLeave").hide();
      $("#btnMute").hide();
      $("#btnUnMute").hide();
      $("#off-cam").hide();
      $("#start-cam").hide();
      $("#screencapture").hide();            
      if (url == "") {        
        this.isLoading= false;
        this.roomid = id;
        $("#room-id").val(id);
        $("#open-room").show();
        $("#join-room").hide();
        $("#open-or-join-room").hide();
        $("#videos-container").addClass("physicianVideo");
        //$("#screencapture").show();
        localStorage.setItem("video-conference-demo", id);        
        localStorage.setItem("conference_room_id", this.roomid);        
      } else {        
        this.isLoading= true;
        this.roomid = url;
        $("#room-id").val(url);
        $("#open-room").hide();
        $("#join-room").hide();
        setTimeout(() => $("#join-room").click(), 8000);
        $("#open-or-join-room").hide();
        $("#videos-container").addClass("patientVideo");
        $("#screencapture").hide();        
        
      }
    },
    checkDevices(){
      var connection = new RTCMultiConnection();
      var deviceStatus = 1;
      connection.DetectRTC.load(function () {

        if (connection.DetectRTC.hasMicrophone === true) {
        // enable microphone
        connection.mediaConstraints.audio = true;
        connection.session.audio = true;
        $("#microphoneError").hide();
        } else {
        //alert('Please attach a microphone device.');
        $("#microphoneError").show();
        $("#open-room").hide();
        $("#deviceError").show();
        deviceStatus = 0;
        // or ignore microphone
        connection.mediaConstraints.audio = false;
        connection.session.audio = false;
        }
        if (connection.DetectRTC.hasWebcam === true) {
        // enable camera
        connection.mediaConstraints.video = true;
        connection.session.video = true;
        $("#camError").hide();
        } else {
        //alert('Please attach a camera device.');
        $("#camError").show();
        $("#open-room").hide();
        $("#deviceError").show();
        deviceStatus = 0;
        
        // or ignore camera
        connection.mediaConstraints.video = false;
        connection.session.video = false;
        }
        if (connection.DetectRTC.hasMicrophone === false && connection.DetectRTC.hasWebcam === false) {
        // he do not have microphone or camera
        // so, ignore capturing his devices
        connection.dontCaptureUserMedia = true;
        }
        // if (connection.DetectRTC.hasSpeakers === false) { // checking for "false"
        // alert('Please attach a speaker device. You will unable to hear the incoming audios.');
        //     $("#speakerError").show();
        //     $("#open-room").hide();
        //     $("#deviceError").show();
        //     deviceStatus = 0;
        // }else{
        //     $("#speakerError").hide();
        // }

        if(deviceStatus==1){
          $("#open-room").show();
          window.location.reload();
        }else{
          $("#open-room").hide();
        }
        
        })
    },
  },  
  mounted() {    
    this.draw();
    setTimeout(() => this.setRoomid(), 2000);        
    this.isLoading= true;
    $("#open-room").hide();
    $("#join-room").hide();
    $("#btnLeave").hide();
    $("#btnMute").hide();
    $("#btnUnMute").hide();
    $("#off-cam").hide();
    $("#start-cam").hide();
    $("#screencapture").hide();     
    $("#open-or-join-room").hide();       
    $("#deviceError").hide();
  },
};
</script>