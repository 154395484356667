<template>
  <header v-if="loginUser.type_uid == 'ipg4bi4vg5'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Super User Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == 'ipg4bi4vg6'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Enrollment Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == 'ipg4bi4v10'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Enrollment Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == 'ipg4bi4vg9'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Manager Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == 'ipg4bi4v11'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Physician Admin Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
  <header v-else-if="loginUser.type_uid == 'ipg4bi4v12'">
    <div class="haeder-left">
      <div class="toggle-option" @click="toogleBar()">
        <span class="bi bi-list"></span>
      </div>
      <h2>Physician N/P Dashboard</h2>
    </div>
    <div class="haeder-right">
      <span v-if="loginUser.extension !=''"
        class="bi bi-telephone call-icon"
        title="Audio Call"
        @click="toogleCall()"
      ></span>
      <span v-if="loginUser.extension !=''"
        class="bi bi-camera-video call-icon"
        title="Video Call"
        @click="toogleVideoCall()"
      ></span>
      <Common />
    </div>
  </header>
</template>
<script>
import Common from "./Common.vue";
import $ from "jquery";
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();

export default {
  name: "Header",
  components: {
    Common: Common,
  },
  data() {
    return {
      profileUrl: null,
      successMessage: null,
      loginUser: JSON.parse(localStorage.getItem("LogIn")),
    };
  },

  mounted() {
        if (!localStorage.getItem("LogIn")){
          this.$router.push("/login");
        }

      if(localStorage.getItem("LogIn")){
      const extension = JSON.parse(localStorage.getItem("LogIn")).extension;
      const dialer_status = extension !== "" ? "1" : "0";
      this.dialer_show = dialer_status;
    }
  },
  methods: {
    toogleBar() {
      $("header").toggleClass("sidebarClone");
      $("nav").toggleClass("sidebarClone");
      $("main").toggleClass("sidebarClone");
      $("footer").toggleClass("sidebarClone");
    },
    toogleCall() {
      $(".sidAudioCall").toggleClass("sidebarDailerActive");
    },
    toogleVideoCall() {
      $(".sidVideoCall").toggleClass("sidebarVideoActive");
    },
  },
};
</script>
